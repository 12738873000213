import BusyIndicator from "react-busy-indicator";
import * as React from "react";
import { NotFoundBoundary, useLoadingRoute } from "react-navi";

export default function Layout({
  children,
  currentUser = null,
  authService = null,
}) {
  // If there is a route that hasn't finished loading, it can be
  // retrieved with `useLoadingRoute()`.
  let loadingRoute = useLoadingRoute();

  return (
    <div className="Layout">
      {/* This component shows a loading indicator after a delay */}
      <BusyIndicator isBusy={!!loadingRoute} delayMs={200} />
      <main>
        {/* Your `<View />` components handle 404s by throwing an
            error, which you can handle with NotFoundBoundary */}
        <NotFoundBoundary render={() => <NotFound />}>
          {children}
        </NotFoundBoundary>
      </main>
    </div>
  );
}

function NotFound() {
  return (
    <div className="Layout-error">
      <h2>404 Not Found</h2>
      <p>Perhaps the page has gone for a long walk?</p>
    </div>
  );
}

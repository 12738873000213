import React from "react";
import { GetData, PostData } from "../../Service/service.ts";
import { Radio, Select, Space, Steps } from "antd";
import { useNavigation } from "react-navi";

const { Option } = Select;

export default function CalculatorForm() {
  const [questions, setQuestions] = React.useState([]);
  const [isQuestionsLoaded, setIsQuestionsLoaded] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(0);

  const [state, setState] = React.useState({
    foodName: "",
    foodCategory: "",
    foodCategoryOther: "",
    locationSource: "",
    typeOfHazard: "",
    typeOfHazardOther: "",
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q5Other: "",
    q6: "",
    q6Other: "",
    q7: "",
    q7Other: "",
    q8: "",
    q8Other: "",
    q9: "",
    q9Other: "",
    q10: "",
    q11: "",
    q11Other: "",
  });

  const [error, setError] = React.useState({
    foodName: "",
    foodCategory: "",
    foodCategoryOther: "",
    locationSource: "",
    typeOfHazard: "",
    typeOfHazardOther: "",
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q5Other: "",
    q6: "",
    q6Other: "",
    q7: "",
    q7Other: "",
    q8: "",
    q8Other: "",
    q9: "",
    q9Other: "",
    q10: "",
    q11: "",
    q11Other: "",
  });

  const nav = useNavigation();

  // React.useEffect(() => {
  //   GetData(`/api/question-table`).then((resp: any) => {
  //     setQuestions(resp);
  //     setIsQuestionsLoaded(true);
  //   });
  // }, []);

  function onChange(name: string, value: any) {
    setState({
      ...state,
      [name]: value,
    });
  }

  function loadQuestions() {
    GetData(`/api/question-table`).then((resp: any) => {
      setQuestions(resp);
      setIsQuestionsLoaded(true);
    });
  }

  React.useEffect(() => {
    loadQuestions();
  }, []);

  function handleValidation() {
    let isValid = true;
    let err: any = [];

    if (currentStep == 0) {
      if (state.foodName == "" || state.foodName == null) {
        isValid = false;
        err["foodName"] = "Food Name is mandatory";
      } else {
        err["foodName"] = "";
      }

      if (state.foodCategory == "" || state.foodCategory == null) {
        isValid = false;
        err["foodCategory"] = "Category is mandatory";
      } else {
        err["foodCategory"] = "";
      }

      if (state.foodCategory == "Other") {
        if (state.foodCategoryOther == "" || state.foodCategoryOther == null) {
          isValid = false;
          err["foodCategoryOther"] = "You must Specify the other";
        } else {
          err["foodCategoryOther"] = "";
        }
      }

      if (state.locationSource == "" || state.locationSource == null) {
        isValid = false;
        err["locationSource"] = "Location & Source is mandatory";
      } else {
        err["locationSource"] = "";
      }

      if (state.typeOfHazard == "" || state.typeOfHazard == null) {
        isValid = false;
        err["typeOfHazard"] = "Type of Hazard is mandatory";
      } else {
        err["typeOfHazard"] = "";
      }

      if (state.typeOfHazard == "Other") {
        if (state.typeOfHazardOther == "" || state.typeOfHazardOther == null) {
          isValid = false;
          err["typeOfHazardOther"] = "You must Specify the other";
        } else {
          err["typeOfHazardOther"] = "";
        }
      }
    }

    if (currentStep == 2) {
      if (state.q1 == "" || state.q1 == null) {
        isValid = false;
        err["q1"] = "This Answer is mandatory";
      } else {
        err["q1"] = "";
      }
    }

    if (currentStep == 1) {
      if (state.q2 == "" || state.q2 == null) {
        isValid = false;
        err["q2"] = "This Answer is mandatory";
      } else {
        err["q2"] = "";
      }
    }

    if (currentStep == 5) {
      if (state.q3 == "" || state.q3 == null) {
        isValid = false;
        err["q3"] = "This Answer is mandatory";
      } else {
        err["q3"] = "";
      }
    }

    if (currentStep == 4) {
      if (state.q4 == "" || state.q4 == null) {
        isValid = false;
        err["q4"] = "This Answer is mandatory";
      } else {
        err["q4"] = "";
      }
    }

    if (currentStep == 3) {
      if (state.q5 == "" || state.q5 == null) {
        isValid = false;
        err["q5"] = "This Answer is mandatory";
      } else {
        err["q5"] = "";
      }

      if(state.q5 == "Other"){
        if (state.q5Other == "" || state.q5Other == null) {
          isValid = false;
          err["q5Other"] = "This Answer is mandatory";
        } else {
          err["q5Other"] = "";
        }
      }
    }

    if (currentStep == 6) {
      if (state.q6 == "" || state.q6 == null) {
        isValid = false;
        err["q6"] = "This Answer is mandatory";
      } else {
        err["q6"] = "";
      }

      if (state.q6 == "Other") {
        if (state.q6Other == "" || state.q6Other == null) {
          isValid = false;
          err["q6Other"] = "This Answer is mandatory";
        } else {
          err["q6Other"] = "";
        }

        // validate the value
        if (parseFloat(state.q6Other) <= 0) {
          isValid = false;
          err["q6Other"] = "Cannot be less than or equal to zero";
        }

        if (parseFloat(state.q6Other) > 100) {
          isValid = false;
          err["q6Other"] = "Cannot be greater than 100";
        }
      }
    }

    if (currentStep == 7) {
      if (state.q7 == "" || state.q7 == null) {
        isValid = false;
        err["q7"] = "This Answer is mandatory";
      } else {
        err["q7"] = "";
      }

      if (state.q7 == "Other") {
        if (state.q7Other == "" || state.q7Other == null) {
          isValid = false;
          err["q7Other"] = "This Answer is mandatory";
        } else {
          err["q7Other"] = "";
        }
      }
    }

    if (currentStep == 8) {
      if (state.q8 == "" || state.q8 == null) {
        isValid = false;
        err["q8"] = "This Answer is mandatory";
      } else {
        err["q8"] = "";
      }

      if (state.q8 == "Other") {
        if (state.q8Other == "" || state.q8Other == null) {
          isValid = false;
          err["q8Other"] = "This Answer is mandatory";
        } else {
          err["q8Other"] = "";
        }

        // validate the value
        if (parseFloat(state.q8Other) <= 0) {
          isValid = false;
          err["q8Other"] = "Cannot be less than or equal to zero";
        }

        if (parseFloat(state.q8Other) > 100) {
          isValid = false;
          err["q8Other"] = "Cannot be greater than 100";
        }
      }
    }

    if (currentStep == 9) {
      if (state.q9 == "" || state.q9 == null) {
        isValid = false;
        err["q9"] = "This Answer is mandatory";
      } else {
        err["q9"] = "";
      }

      if (state.q9 == "Other") {
        if (state.q9Other == "" || state.q9Other == null) {
          isValid = false;
          err["q9Other"] = "This Answer is mandatory";
        } else {
          err["q9Other"] = "";
        }

        // validate the value
        if (parseFloat(state.q9Other) <= 0) {
          isValid = false;
          err["q9Other"] = "Cannot be less than or equal to zero";
        }
      }
    }

    if (currentStep == 10) {
      if (state.q10 == "" || state.q10 == null) {
        isValid = false;
        err["q10"] = "This Answer is mandatory";
      } else {
        err["q10"] = "";
      }
    }

    if (currentStep == 11) {
      if (state.q11 == "" || state.q11 == null) {
        isValid = false;
        err["q11"] = "This Answer is mandatory";
      } else {
        err["q11"] = "";
      }

      if (state.q11 == "Other") {
        if (state.q11Other == "" || state.q11Other == null) {
          isValid = false;
          err["q11Other"] = "This Answer is mandatory";
        } else {
          err["q11Other"] = "";
        }

        // validate the value
        if (parseFloat(state.q9Other) < 0) {
          isValid = false;
          err["q11Other"] = "Cannot be less than zero";
        }
      }
    }

    setError(err);

    return isValid;
  }

  // const nav = useNavigation()

  function onNextStep() {
    if (currentStep < questions.length) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  }

  function onSubmit() {
    if (handleValidation()) {
      if (currentStep === questions.length) {
        PostData(`/api/answer-table`, {
          ...state,
        }).then((resp: any) => {
          if (resp.status) {
            nav.navigate(`/result?id=${resp.id}&checksum=${resp.checksum}`);
          }
        });
      } else {
        onNextStep();
      }
    }
  }

  function onClear() {
    setState({
      ...state,
      foodName: "",
      foodCategory: "",
      foodCategoryOther: "",
      locationSource: "",
      typeOfHazard: "",
      typeOfHazardOther: "",
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q5Other: "",
      q6: "",
      q6Other: "",
      q7: "",
      q7Other: "",
      q8: "",
      q8Other: "",
      q9: "",
      q9Other: "",
      q10: "",
      q11: "",
      q11Other: "",
    });

    setError({
      ...error,
      foodName: "",
      foodCategory: "",
      foodCategoryOther: "",
      locationSource: "",
      typeOfHazard: "",
      typeOfHazardOther: "",
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q5Other: "",
      q6: "",
      q6Other: "",
      q7: "",
      q7Other: "",
      q8: "",
      q8Other: "",
      q9: "",
      q9Other: "",
      q10: "",
      q11: "",
      q11Other: "",
    });
  }

  function goToNextStep() {
    if (handleValidation()) {
      setCurrentStep((s) => s + 1);
    }
  }

  return (
    <div>
      {" "}
      <ol className="flex items-center justify-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-2 rtl:space-x-reverse">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((el: any, index: number) => (
            <li
              key={index}
              className={`flex items-center ${currentStep === el ? 'text-blue-600 dark:text-blue-500' : ''}`}
            >
              <span className="flex items-center justify-center w-5 h-5 me-2 text-xs border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                {el}
              </span>
              Q{el}
              {el < 11 && <svg
                className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 12 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m7 9 4-4-4-4M1 9l4-4-4-4"
                />
              </svg>}
            </li>
          ))}
        </ol>
      <div className="w-full max-w-3xl mx-auto text-left pl-5 py-0">
        
        
        <form className="">
          <div className="flex flex-wrap -mx-3 mb-6 mt-9">
            {currentStep === 0 && (
              <>
                <div className="w-full md:w-1/2 px-3 mb-5">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Name of the Food <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    value={state.foodName}
                    onChange={(e: any) => onChange("foodName", e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=""
                    required
                  />
                  {error.foodName && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className="font-medium">Error:</span>{" "}
                      {error.foodName}
                    </p>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-5 ">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Food Category <span className="text-red-600">*</span>
                  </label>
                  <select
                    value={state.foodCategory}
                    onChange={(e: any) =>
                      onChange("foodCategory", e.target.value)
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Choose a Category</option>
                    <optgroup label="Animal Origin">
                      <option value="Meat group">Meat group</option>
                      <option value="Milk group">Milk group</option>
                      <option value="Egg">Egg</option>
                      <option value="Other(Animal)">Other</option>
                    </optgroup>
                    <optgroup label="Animal Origin">
                      <option value="Vegetable group">Vegetable group</option>
                      <option value="Cereals">Cereals</option>
                      <option value="Pulses">Pulses</option>
                      <option value="Other(Plant)">Other</option>
                    </optgroup>
                    <optgroup label="Other">
                      <option value="Other">
                        Others (You need to Specify)
                      </option>
                    </optgroup>
                  </select>

                  {error.foodCategory && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className="font-medium">Error:</span>{" "}
                      {error.foodCategory}
                    </p>
                  )}
                  {(state.foodCategory == "Other(Animal)" ||
                    state.foodCategory == "Other(Plant)" ||
                    state.foodCategory == "Other") && (
                    <div className="mb-5 mt-2">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Specify Others Food Categories{" "}
                        <span className="text-red-600">*</span>
                      </label>
                      <input
                        value={state.foodCategoryOther}
                        onChange={(e: any) =>
                          onChange("foodCategoryOther", e.target.value)
                        }
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                    </div>
                  )}
                  {error.foodCategoryOther && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className="font-medium">Error:</span>{" "}
                      {error.foodCategoryOther}
                    </p>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-5">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Location and Source <span className="text-red-600">*</span>
                  </label>
                  <input
                    value={state.locationSource}
                    onChange={(e: any) =>
                      onChange("locationSource", e.target.value)
                    }
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  />
                  {error.locationSource && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className="font-medium">Error:</span>{" "}
                      {error.locationSource}
                    </p>
                  )}
                </div>

                <div className="w-full md:w-1/2 px-5 mb-5">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Type of Hazard <span className="text-red-600">*</span>
                  </label>
                  <select
                    value={state.typeOfHazard}
                    onChange={(e: any) =>
                      onChange("typeOfHazard", e.target.value)
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Choose Type</option>

                    <option value="Microbial organisms">
                      Microbial organisms
                    </option>
                    <option value="Antibiotic residues">
                      Antibiotic residues
                    </option>
                    <option value="Pesticide residues">
                      Pesticide residues
                    </option>
                    <option value="Heavy metals">Heavy metals</option>
                    <option value="Other">Other</option>
                  </select>
                  {error.typeOfHazard && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className="font-medium">Error:</span>{" "}
                      {error.typeOfHazard}
                    </p>
                  )}

                  {state.typeOfHazard == "Other" && (
                    <div className="mb-5 mt-2">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Specify Others Type of Hazards{" "}
                        <span className="text-red-600">*</span>
                      </label>
                      <input
                        value={state.typeOfHazardOther}
                        onChange={(e: any) =>
                          onChange("typeOfHazardOther", e.target.value)
                        }
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                    </div>
                  )}
                  {error.typeOfHazardOther && (
                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                      <span className="font-medium">Error:</span>{" "}
                      {error.typeOfHazardOther}
                    </p>
                  )}
                </div>

                <div className="w-full md:w-1/2 px-3 mb-5"></div>
                <div className="w-full md:w-1/2 px-3 mb-5 flex justify-end">
                  <button
                    type="button"
                    onClick={() => goToNextStep()}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-10 mr-2"
                  >
                    Next Step
                  </button>
                </div>
              </>
            )}
          </div>

          {currentStep !== 0 && (
            <div className="flex flex-wrap -mx-3 mb-6">
              {Array.isArray(questions) &&
                questions.map((el: any, index: number) => {
                  if (el.questionLabel != 5) {
                    if (currentStep === el.questionId) {
                      return (
                        <div
                          key={index}
                          className="flex flex-wrap -mx-3 mb-0 w-full"
                        >
                          <div
                            className="w-full md:w-full px-3 mb-3 "
                            style={{ minHeight: 270 }}
                          >
                            <div>
                              <label style={{marginLeft: '0', paddingLeft: '0'}} className="block mb-0 text-lg px-5 font-medium text-blue-800 dark:text-blue-800">
                                Q#{el.questionId}. {el.questionTitle}{" "}
                                <span className="text-red-600">*</span>
                              </label>
                              <Radio.Group
                                buttonStyle="solid"
                                onChange={(e: any) =>
                                  onChange(
                                    `q${el.questionLabel}`,
                                    e.target.value
                                  )
                                }
                                value={state[`q${el.questionLabel}`]}
                              >
                                <Space direction="vertical">
                                  {Array.isArray(el.QuestionOptionTable) &&
                                    el.QuestionOptionTable.map(
                                      (el1: any, index1: number) => (
                                        <Radio
                                          value={el1.questionOptionValue}
                                          key={index1}
                                          style={{ fontSize: 14 }}
                                        >
                                          {el1.questionOptionTitle}
                                          {el1.questionOptionDescription
                                            ? ` (${el1.questionOptionDescription})`
                                            : ""}
                                        </Radio>
                                      )
                                    )}
                                </Space>
                              </Radio.Group>
                            </div>

                            {error[`q${el.questionLabel}`] && (
                              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                                <span className="font-medium">Error:</span>{" "}
                                {error[`q${el.questionLabel}`]}
                              </p>
                            )}

                            {state[`q${el.questionLabel}`] == "Other" && (
                              <div className="mb-5 mt-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                  Specify Other Value
                                  <span className="text-red-600">*</span>
                                </label>
                                <input
                                  type="number"
                                  value={state[`q${el.questionLabel}Other`]}
                                  onChange={(e: any) =>
                                    onChange(
                                      `q${el.questionLabel}Other`,
                                      e.target.value
                                    )
                                  }
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  required
                                />
                              </div>
                            )}

                            {error[`q${el.questionLabel}Other`] && (
                              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                                <span className="font-medium">Error:</span>{" "}
                                {error[`q${el.questionLabel}Other`]}
                              </p>
                            )}
                          </div>
                          <div className="w-full md:w-1/2 px-3 mb-5">
                            <button
                              type="button"
                              onClick={() => setCurrentStep((s) => s - 1)}
                              className=" hover:bg-gray-50 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-10 mr-2"
                            >
                              Previous Step
                            </button>
                          </div>
                          <div className="w-full md:w-1/2 px-3 mb-5 flex justify-end">
                            {currentStep === 11 ? (
                              <button
                                type="button"
                                onClick={() => onSubmit()}
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-10 mr-2"
                              >
                                Calculate
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => goToNextStep()}
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-10 mr-2"
                              >
                                Next Step
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    }
                  } else {
                    if (currentStep === el.questionId) {
                      return (
                        <div
                          key={index}
                          className="flex flex-wrap -mx-3 mb-6 w-full"
                        >
                          <div
                            className="w-full md:w-full px-3 mb-5"
                            style={{ minHeight: 270 }}
                          >
                            <label className="block mb-2 text-xl font-medium text-blue-800 dark:text-blue-800">
                              Q#{el.questionId}. {el.questionTitle}{" "}
                              <span className="text-red-600">*</span>
                            </label>
                            <Select
                              showSearch
                              allowClear
                              dropdownAlign={{ offset: [0, 8] }}
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              value={state[`q${el.questionLabel}`]}
                              onChange={(v: any) =>
                                onChange(`q${el.questionLabel}`, v)
                              }
                              filterOption={(
                                input: string,
                                option?: { label: string; value: string }
                              ) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                            >
                              {Array.isArray(el.QuestionOptionTable) &&
                                el.QuestionOptionTable.map(
                                  (el1: any, index1: number) => (
                                    <Option
                                      key={index1}
                                      value={el1.questionOptionValue}
                                    >
                                      {`${el1.questionOptionTitle}`}
                                    </Option>
                                  )
                                )}
                            </Select>
                            {error[`q${el.questionLabel}`] && (
                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                              <span className="font-medium">Error:</span>{" "}
                              {error[`q${el.questionLabel}`]}
                            </p>
                          )}
                           {state[`q${el.questionLabel}`] == "Other" && (
                              <div className="mb-5 mt-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                  Specify Other Value (between 1 to 160 million)
                                  <span className="text-red-600">*</span>
                                </label>
                                <input
                                  type="number"
                                  value={state[`q${el.questionLabel}Other`]}
                                  onChange={(e: any) =>
                                    onChange(
                                      `q${el.questionLabel}Other`,
                                      e.target.value
                                    )
                                  }
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  required
                                />
                              </div>
                            )}

                            {error[`q${el.questionLabel}Other`] && (
                              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                                <span className="font-medium">Error:</span>{" "}
                                {error[`q${el.questionLabel}Other`]}
                              </p>
                            )}
                          </div>
                          
                          <div className="w-full md:w-1/2 px-3 mb-0 mt-0">
                            <button
                              type="button"
                              onClick={() => setCurrentStep((s) => s - 1)}
                              className=" hover:bg-gray-50 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-10 mr-2"
                            >
                              Previous Step
                            </button>
                          </div>
                          <div className="w-full md:w-1/2 px-3 mb-3 mt-0 flex justify-end">
                            <button
                              type="button"
                              onClick={() => goToNextStep()}
                              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-10 mr-2"
                            >
                              Next Step
                            </button>
                          </div>
                        </div>
                      );
                    }
                  }
                })}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

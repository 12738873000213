import { Descriptions, Radio, Result, Space } from "antd";
import React from "react";
import { GetData } from "../../Service/service.ts";
import { useReactToPrint } from "react-to-print";

interface IProps {
  params: any;
  result: any;
}

export default function DownloadPage({ params, result }: IProps) {
  const [questions, setQuestions] = React.useState([]);
  const [isQuestionsLoaded, setIsQuestionsLoaded] = React.useState(false);
  function loadQuestions() {
    GetData(`/api/question-table`).then((resp: any) => {
      setQuestions(resp);
      setIsQuestionsLoaded(true);
    });
  }

  React.useEffect(() => {
    loadQuestions();
  }, []);

  function formatNumberToExponential(number: number) {
    var formattedNumber = number.toExponential();
    var parts = formattedNumber.split("e");
    var mantissa = parseFloat(parts[0]);
    var exponentValue = parseInt(parts[1]);
    var formattedExponent = exponentValue.toFixed(0); // Change the precision as needed
    var finalFormattedNumber = mantissa.toFixed(2) + "e" + formattedExponent;
    var html = (
      <div>
        {mantissa.toFixed(2)} x 10{<sup>{formattedExponent}</sup>}
      </div>
    );
    return html;
  }

  function getPopulationStateName(d: any, population: string) {
    console.log("🚀 ~ getPopulationStateName ~ d:", d);
    let dd = d.find((el: any) => el.questionOptionValue == population);
    if (dd) {
      return dd.questionOptionTitle;
    }
  }

  const componentRef: any = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <div className="flex flex-row justify-center mt-4 mb-4">
        <button
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          onClick={() => handlePrint()}
        >
          Print
        </button>
      </div>

      <div className="flex flex-row justify-center align-middle max-w-full">
        <div ref={componentRef} className="w-6/12 print:w-full print:p-10">
          <h2 className="mt-5 font-semibold  text-indigo-600 text-center text-4xl">
            Quality Control Laboratory
          </h2>
          <h1 className="mt-1 text-center text-xl  font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-2xl print:text-2xl dark:text-white">
            ICAR - National Research Center on Pig
          </h1>
          <p className="text-center text-xl font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
            Rani, Guwahati - 781131
          </p>
          <p className="mb-6 text-center text-1xl  font-bold text-gray-900 md:text-2xl sm:px-16 xl:px-36 dark:text-gray-400">
            Food Safety Risk Calculator
          </p>

          <div style={{ border: "solid 1px grey" }} className="mb-4">
            <Result
              icon={
                <div className="flex justify-center items-center mb-0">
                  <img src="/images/fosarica-logo.png" className="w-40" />
                </div>
              }
              title={
                <div className="text-4xl font-extrabold text-red-600 mt-0">
                  Risk Ranking: {result.riskRanking}
                </div>
              }
              subTitle={"Calculated according to the insputs of the form"}
            />

            <div className="flex flex-wrap -mx-3 mb-6 mt-[-40px] justify-center">
              <div
                className="m-4 bg-white rounded-lg md:p-8 dark:bg-gray-800 "
                id="stats"
                role="tabpanel"
                aria-labelledby="stats-tab"
              >
                <div className="w-full p-4 bg-white rounded-lg md:p-2 dark:bg-gray-800 text-center">
                  <h2 className="mt-1 text-lg font-bold leading-6 text-red-600">
                   Risk Estimates
                  </h2>
                  <div className="mb-0 ">
                    <span className="text-lg font-medium">
                      Probability of Illness per day per consumer of interest:
                    </span>
                    <span className="text-gray-500 dark:text-gray-400 font-bold ml-1 inline-block">
                      {formatNumberToExponential(
                        parseFloat(result.probabilityOfIllness)
                      )}
                    </span>
                  </div>
                  <div className="mb-0">
                    <span className="text-lg font-medium">
                      Total predicted Illness per annum in population of
                      Interests:
                    </span>
                    <span className="text-gray-500 dark:text-gray-400 font-bold ml-1 inline-block">
                      {formatNumberToExponential(
                        parseFloat(result.totalPridictedIllness)
                      )}
                    </span>
                  </div>
                  <div className="mb-0">
                    <span className="text-lg font-medium">
                      "Comparative Risk" factor in the population of interest:
                    </span>
                    <span className="text-gray-500 dark:text-gray-400 font-bold ml-1 inline-block">
                      {formatNumberToExponential(
                        parseFloat(result.comperativeRisk)
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Descriptions size="small" column={2} bordered={true}>
            <Descriptions.Item label="Name of the Food">
              {result.foodName}
            </Descriptions.Item>
            <Descriptions.Item label="Food Category">
              {result.foodCategory}
            </Descriptions.Item>
            <Descriptions.Item label="Location & Source">
              {result.locationSource}
            </Descriptions.Item>
            <Descriptions.Item label="Type of Hazard">
              {result.typeOfHazard}
            </Descriptions.Item>
          </Descriptions>

          <div className="flex flex-wrap -mx-3 mb-6 mt-4">
            {Array.isArray(questions) &&
              questions.map((el: any, index: number) => {
                if (el.questionLabel != 5) {
                  return (
                    <div key={index} className="w-full md:w-full px-3 mb-5">
                      <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Q#{el.questionId}. {el.questionTitle}{" "}
                          <span className="text-red-600">*</span>
                        </label>
                        <Radio.Group
                          // onChange={(e: any) =>
                          //   onChange(`q${el.questionLabel}`, e.target.value)
                          // }
                          value={result[`q${el.questionLabel}`]}
                        >
                          <Space direction="vertical">
                            {Array.isArray(el.QuestionOptionTable) &&
                              el.QuestionOptionTable.map(
                                (el1: any, index1: number) => (
                                  <Radio
                                    value={el1.questionOptionValue}
                                    key={index1}
                                  >
                                    {el1.questionOptionTitle}
                                    {el1.questionOptionDescription
                                      ? ` (${el1.questionOptionDescription})`
                                      : ""}
                                  </Radio>
                                )
                              )}
                          </Space>
                        </Radio.Group>
                      </div>

                      {result[`q${el.questionLabel}`] == "Other" && (
                        <div className="mb-5 mt-2">
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Specify Other Value
                            <span className="text-red-600">*</span>
                          </label>
                          <div>{result[`q${el.questionLabel}Other`]}</div>
                        </div>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div key={index}>
                      <div className="w-full md:w-full px-3 mb-5">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                          Q#{el.questionId}. {el.questionTitle}{" "}
                          <span className="text-red-600">*</span>
                        </label>
                        <div className="font-bold">
                          {result[`q${el.questionLabel}`]} (
                          {getPopulationStateName(
                            el.QuestionOptionTable,
                            result[`q${el.questionLabel}`]
                          )}
                          )
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
      <div className="max-w-full mx-auto flex justify-center">
        <div className="w-full mb-2 md:w-3/5 p-4 bg-white border border-gray-150 rounded-lg md:p-2 dark:bg-gray-800 text-center">
          <h1 className="mt-1 text-center text-lg  font-bold leading-none tracking-tight text-blue-900 md:text-5xl lg:text-2xl print:text-2xl dark:text-white">
            Disclaimer
          </h1>
          <span>
            FoSaRiCa has been developed based on the concepts outlined in the
            paper ‘Ross, T. and Sumner, J.L. (2002). A simple,
            spreadsheet-based, food safety risk assessment tool. International
            Journal of Food Microbiology, 77:39-53’. FoSaRiCa has been developed
            in Javascript framework. Necessary modifications are incorporated in
            the software to make it convenient for the Indian users. The outputs
            are only as reliable as the data entered, and users are urged to
            remain aware of the intended uses and limitations of the program.
          </span>
        </div>
      </div>
    </div>
  );
}

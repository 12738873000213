import React from "react";
import { useNavigation } from "react-navi";

export default function Homepage() {
  const nav = useNavigation();

  return (
    <div>
      {" "}
      <div className="bg-white py-8 lg:py-24">
        <div className="mx-auto max-w-7xl px-4 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <div className="flex justify-center">
              <img src="/images/fosarica-logo.png" className="w-50 h-40 text-center" />
            </div>
            {/* <h4 className="text-4xl text-center font-bold leading-7 text-indigo-600">
              FoSaRiCa
            </h4>
            <p className="mt-2 text-md text-center font-semibold tracking-tight text-gray-900 sm:text-1xl">
              Food Safety Risk Calculator
            </p> */}
            <h2 className="mt-3 text-md font-semibold leading-6 text-indigo-600">
              About FoSaRiCa
            </h2>
            <p className="mt-0 text-sm leading-5 text-gray-600 text-align:justify-center flex justify-between" style={{textAlign: "justify"}}>
              FoSaRiCa (Food Safety Risk Calculator) has been designed to suit 
              the Indian users and is a simple tool for ranking the risks
              associated with different foods by considering the interplay of
              factors that contribute to foodborne disease. The tool can also be
              used to explore the effect of different risk-reduction strategies,
              or the extent of change required to bring about a desired
              reduction in risk in the food. The tool can be used by risk
              managers, researchers, students and others without extensive
              experience in risk modelling.
            </p>
            <h2 className="mt-4 text-md font-semibold leading-5 text-indigo-600">
              Instructions for using FoSaRiCa
            </h2>
            <p className="mt-0 text-sm leading-5 text-gray-600">
              <ol className="list-decimal pl-4 lg:text-left">
                <li className="pl-1">
                  Option is provided for the user to enter the basic details of
                  the food for which the risk rank is intended to calculate.
                </li>
                <li className="pl-1">
                  FoSaRiCa contains eleven compulsory questions that are to be
                  answered by the user. The answers are to be selected from the
                  drop-down options. The numbers shown in the brackets indicate
                  the underlying values that are actually used in the
                  calculations.
                </li>
                <li className="pl-1">
                  For questions with option 'Other', it is possible for the user
                  to select a value that is not noted in the dropdown option.
                  However, it may note that the system will not check the
                  accuracy of the input, e.g. if the value is within the
                  appropriate range.
                </li>
                <li className="pl-1">
                  In order to calculate the risk rank, the user has to click on
                  the 'Calculate' button, and the results will be displayed in
                  the web page.
                </li>
                <li className="pl-1">
                  Option is provided for the user to preserve the results in a
                  PDF form.
                </li>
              </ol>
            </p>
          </div>
          <div className="mt-9 flex items-center justify-center gap-x-6">
            <a
              onClick={() => nav.navigate("/calculator")}
              className="cursor-pointer rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go to Calculator
            </a>
            {/* <a
              target="_blank"
              href="https://www.sciencedirect.com/science/article/abs/pii/S0168160502000612"
              className="cursor-pointer text-sm font-semibold leading-6 text-gray-900"
            >
              Read the paper <span aria-hidden="true">→</span>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}

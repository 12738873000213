import * as React from "react";
import { mount, route, withView, redirect, lazy, map, compose } from "navi";
import Layout from "../Layout.js";
import { View } from "react-navi";
import Homepage from "../Pages/Homepage/Homepage.tsx";
import CalculatorPage from "../Pages/Calculator/CalculatorPage.tsx";
import ResultPage from "../Pages/Results/ResultPage.tsx";
import { GetData } from "../Service/service.ts";
import DownloadPage from "../Pages/Downloads/DownloadPage.tsx";

export default compose(
  withView((request: any, context: any, args: any) => (
    <Layout currentUser={context.currentUser} authService={context.authService}>
      <main>
        <View />
      </main>
    </Layout>
  )),
  mount({
    "/": map((request: any, context: any) => {
      return route({
        title: "Food Safety Calculator",
        head: (
          <>
            <meta name="description" content="Payment-App" />
          </>
        ),
        view: <Homepage {...context} />,
      });
    }),
    "/calculator": map((request: any, context: any) => {
      return route({
        title: "Food Safety Calculator",
        head: (
          <>
            <meta name="description" content="Payment-App" />
          </>
        ),
        view: <CalculatorPage />,
      });
    }),
    "/result": map((request: any, context: any) => {
      let params = request.params;

      return GetData(`/api/answer-table/${params.id}/${params.checksum}`).then((resp: any) => {
        return route({
          title: "Food Safety Calculator",
          head: (
            <>
              <meta name="description" content="Payment-App" />
            </>
          ),
          view: <ResultPage params={params} result={resp} />,
        });
      });
    }),

    "/download": map((request: any, context: any) => {
      let params = request.params;

      return GetData(`/api/answer-table/${params.id}/${params.checksum}`).then((resp: any) => {
        return route({
          title: "Food Safety Calculator",
          head: (
            <>
              <meta name="description" content="Payment-App" />
            </>
          ),
          view: <DownloadPage params={params} result={resp} />,
        });
      });
    }),
  })
);

import React from "react";
import "./App.css";
import { HelmetProvider } from "react-navi-helmet-async";
import { Router, View } from "react-navi";
import Layout from "./Layout.js";
import { Suspense } from "react";
import Routes from "./Routes/routes.tsx";

function App() {
  return (
   
      <Router routes={Routes}>
        <HelmetProvider>
          <Layout>
            <Suspense fallback={null}>
              <View />
            </Suspense>
          </Layout>
        </HelmetProvider>
      </Router>
    
  );
}

export default App;

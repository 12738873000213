import { Result } from "antd";
import React from "react";
import { useNavigation } from "react-navi";

interface IProps {
  params: any;
  result: any;
}

export default function ResultPage({ params, result }: IProps) {
  function formatNumberToExponential(number: number) {
    var formattedNumber = number.toExponential();
    var parts = formattedNumber.split("e");
    var mantissa = parseFloat(parts[0]);
    var exponentValue = parseInt(parts[1]);
    var formattedExponent = exponentValue.toFixed(0); // Change the precision as needed
    var finalFormattedNumber = mantissa.toFixed(2) + "e" + formattedExponent;
    var html = (
      <div>
        {mantissa.toFixed(2)} x 10{<sup>{formattedExponent}</sup>}
      </div>
    );
    return html;
  }

  const nav = useNavigation();
  return (
    <div>
      <div className="relative isolate overflow-hidden bg-blue-700 py-4 sm:py-16">
        {/* <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        /> */}

        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div
            className="mx-auto max-w-full lg:mx-0 flex justify-center"
            style={{ flexDirection: "column" }}
          >
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Results
            </h2>
            <p className="mt-6 text-lg leading-0 text-gray-300">
              Here are the results of your calculations, you can print or
              download the results
              <br />#{params.checksum}
            </p>
          </div>
        </div>
      </div>

      <Result
        icon={
          <div className="flex justify-center items-center mb-0">
            <img src="/images/fosarica-logo.png" className="w-40"/>
            </div>
            }
        title={
          <div className="text-4xl font-extrabold text-red-600 mt-0">
            Risk Ranking: {result.riskRanking}
          </div>
        }
        subTitle={"Calculated according to the inputs of the form"}
      />

      <div
        className="p-4 bg-white rounded-lg md:p-2 dark:bg-gray-800"
        id="stats"
        role="tabpanel"
        aria-labelledby="stats-tab"
      >
        <div className="flex flex-col md:flex-row items-center justify-center">
          <div className="w-full md:w-1/2 p-4 bg-white border border-gray-150 rounded-lg md:p-2 dark:bg-gray-800 text-center hover:border-blue-300 hover:shadow-md">
          <h2 className="mt-1 text-lg font-bold leading-6 text-red-600">
              Risk Estimates
            </h2>
            <div className="mb-0">
              <span className="text-lg font-medium">
                Probability of Illness per day per consumer of interest:
              </span>
              <span className="text-gray-500 dark:text-gray-400 font-bold ml-1 inline-block">
                {formatNumberToExponential(
                  parseFloat(result.probabilityOfIllness)
                )}
              </span>
            </div>
            <div className="mb-0">
              <span className="text-lg font-medium">
                Total predicted Illness per annum in population of Interests:
              </span>
              <span className="text-gray-500 dark:text-gray-400 font-bold ml-1 inline-block">
                {formatNumberToExponential(
                  parseFloat(result.totalPridictedIllness)
                )}
              </span>
            </div>
            <div>
              <span className="text-lg font-medium">
                "Comparative Risk" factor in the population of interest:
              </span>
              <span className="text-gray-500 dark:text-gray-400 font-bold ml-1 inline-block">
                {formatNumberToExponential(parseFloat(result.comperativeRisk))}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-center gap-x-6 mb-10">
        <a
          onClick={() =>
            nav.navigate(
              `/download?id=${params.id}&checksum=${params.checksum}`
            )
          }
          className="cursor-pointer rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Download Report in PDF Format
        </a>
      </div>
      <div
        style={{ marginTop: "-0.5rem" }}
        className="mt-1 flex items-center justify-center gap-x-6 mb-10"
      >
        <a
          href="/"
          className="cursor-pointer rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Back to Home Page
        </a>
        <a
          target="_blank"
          href="https://www.sciencedirect.com/science/article/abs/pii/S0168160502000612"
          className="cursor-pointer text-sm font-semibold leading-6 text-gray-900 hover:bg-indigo-600  hover:text-white hover:rounded-md hover:font-semibold hover:px-3.5 hover:py-2.5"
        >
          Read the paper <span aria-hidden="true">→</span>
        </a>
      </div>
    </div>
  );
}
